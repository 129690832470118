<div class="cont container-fluid">
    <div class="row">
        <div class="col-sm-9" style="padding: 3vh;">
            <h2 style="margin: 2rem;font-size: 2rem; color: #185ea6;">⮞ Αποτελέσματα Αναζήτησης</h2>
            <div class="container-fluid">
                <div class="row">
                    <p *ngIf="results.length === 0" class="notFound">Δεν βρέθηκαν αποτελέσματα για την αναζήτηση "{{queryText}}"</p>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-4" *ngFor="let item of currentPages">
                        <div class="card shadow" >
                            <div class="card-body text-center" style="height: 30rem;">
                                <a href="#" class="col-12">
                                    <img *ngIf="item && item.attributes.cover.data !=null" class="img-fluid" [src]="item.attributes.cover.data.attributes.url" alt="item.attributes.cover.data.attributes.name" style="width:13rem">
                                    <img *ngIf="item && item.cover" class="img-fluid" [src]="item.cover" alt="cover_image" style="width:15rem">
                                </a>
                                <ul class="col-12 list-inline mt-3">
                                    <li class="list-inline-item" translate><i class="fas fa-user"></i>published</li>
                                    <li class="list-inline-item"><i class="far fa-clock"></i>{{item && item.attributes.published}}</li>
                                </ul>
                                <hr>
                                <p class="lead">{{item && item.attributes.title}}</p>
                                <a class="btn btn-outline-dark my-2" role="button" routerLink="/page/{{item && item.id}}" translate>readMore</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-end">
                <p-paginator 
                    [rows]="1" 
                    [totalRecords]="numberOfPages"
                    (onPageChange)="paginate($event)"
                >
                </p-paginator>
            </div>
        </div>
        <div class="col-sm-3">
            <app-banners></app-banners>
        </div>
    </div>
</div>

