<div class="container">
    <div class="row">
        <div class="left col-md-5">
            <div style="color: #026AA2;font-weight: bold;">
                <p style="margin-bottom:0px" translate>welcome</p>
                <h1 class="chamber" translate>chamber</h1>
                <p style="color: #475467;" translate>info</p>
            </div>

        </div>
        <div class="cont2 col-md-7" style="height: 406px;padding-right: 0px;padding-left: 0px;">
            <!-- <p-galleria [(value)]="images" [numVisible]="5" [circular]="true" [showItemNavigators]="true" [showThumbnails]="false" [responsiveOptions]="responsiveOptions" [containerStyle]="{ 'max-width': '640px' }">
                <ng-template pTemplate="item" let-item>
                    <img [src]="item.url" style="height: 406px;object-fit: cover;" class="w-100"/>
                </ng-template>
            </p-galleria> -->
            <ngb-carousel *ngIf="images" [interval]="3000" class="number-slide1" [animation]="true"
                style=" background-color: rgba(180, 180, 180, 0.6);">
                <ng-template ngbSlide *ngFor="let item of images">

                    <a href="{{item.attributes.image.data?.attributes.url}}" target="_blank"><img [src]="item.attributes.image.data.attributes.url" alt="{{item.attributes.image.data.attributes.url}}"
                            class="w-100 img-fluid" style="height: 406px;object-fit: cover;"></a>

                </ng-template>
            </ngb-carousel>

        </div>
    </div>
    <div class="row" style="background: #EEF0F2;padding: 3rem;">
        <div class="cc col-md-3">
            <div>
                <img src="'../../../../assets/desktop.png" class="img-fluid" style="width: 2rem;" alt="desktop">
            </div>
            <a class="link" href="https://echamber.epimelitiriofokidas.gr/eChamber/login.php" target="_blank" translate>eChamber</a>
            <p class="text" translate>textChamber</p>
        </div>
        <div class="cc col-md-3">
            <div><img src="'../../../../assets/networking.png" class="img-fluid" style="width: 2rem;" alt="desktop">
            </div>
            <a class="link" href="https://www.businessportal.gr/" translate>gemi</a>
            <p class="text" translate>textgemi</p>
        </div>
        <div class="cc col-md-3">
            <div><img src="'../../../../assets/customer.png" class="img-fluid" style="width: 2rem;" alt="desktop"></div>
            <a class="link" href="https://eyms.businessportal.gr/auth" target="_blank" translate>onestop</a>
            <p class="text" translate>textonestop</p>
        </div>
        <div class="cc col-md-3">
            <div><img src="'../../../../assets/subscription.png" class="img-fluid" style="width: 2rem;" alt="desktop">
            </div>
            <a class="link" routerLink="#" translate>membershipSubscriptions</a>
            <p class="text" translate>textsub</p>
        </div>
    </div>
    <div class="row" style="margin-top: 2rem;">
        <div class="quick col-md-4">
            <div style="border-radius: 24px; background: #0284CA;padding: 1rem;">
                <div style="display: flex;flex-direction: column;margin-left: 1rem;">
                    <h3 style="color: var(--base-white, #FFF); " translate>quickLinks</h3>
                    <p style="color: #B9E6FE; ">Βρείτε γρήγορα αυτό που θέλετε</p>
                </div>

                <div class="quick-link" style="margin-top: 1rem;">
                    <a class="link2" routerLink="/category/2/1" translate>announcementsReleases</a>
                    <img src="/assets/arrow-circle-broken-right.png" class="img-fluid" alt="arrow-circle-broken-right">
                </div>

                <div class="quick-link">
                    <a class="link2" routerLink="/category/6/1" translate>enterpriseInformation</a><br>
                    <img src="/assets/arrow-circle-broken-right.png" class="img-fluid" alt="arrow-circle-broken-right">
                </div>
                <div class="quick-link">
                    <a class="link2" routerLink="/category/3/1" translate>eventsDays</a><br>
                    <img src="/assets/arrow-circle-broken-right.png" class="img-fluid" alt="arrow-circle-broken-right">
                </div>
                <div class="quick-link">
                    <a class="link2" routerLink="/category/4/1" translate>seminars</a><br>
                    <img src="/assets/arrow-circle-broken-right.png" class="img-fluid" alt="arrow-circle-broken-right">
                </div>
                <div class="quick-link">
                    <a class="link2" routerLink="/category/5/1" translate>contests</a><br>
                    <img src="/assets/arrow-circle-broken-right.png" class="img-fluid" alt="arrow-circle-broken-right">
                </div>

                <div class="quick-link">
                    <a class="link2" routerLink="/category/7/1" translate>exhibitions</a><br>
                    <img src="/assets/arrow-circle-broken-right.png" class="img-fluid" alt="arrow-circle-broken-right">
                </div>

                <div class="quick-link">
                    <a class="link2" routerLink="/category/8/1" translate>live</a><br>
                    <img src="/assets/arrow-circle-broken-right.png" class="img-fluid" alt="arrow-circle-broken-right">
                </div>

                <div>
                    <img src="/assets/Group 2.png" alt="locker-image" class="img-fluid">
                </div>

            </div>

        </div>
        <div class="latest col-md-8">

            <h3 style="margin-top: 0.5rem;" translate>latestNews</h3>
            <p>Οτιδήποτε νεότερο από το επιμελητήριο Φωκίδος</p>
            <div class="container-fluid">
                <div class="row" *ngFor="let item of latestNews" style="padding: 0.5rem;">
                    <div class="col-md-4">
                        <img [src]="item?.attributes.cover" class="img-fluid" style="height: 10rem;">
                    </div>
                    <div class="col-md-8">
                        <h5>{{item?.attributes.title}}</h5>
                        <a class="btn_primary" style='font-size: 1rem;' routerLink="/page/{{item.id}}"
                            translate>more</a>
                    </div>
                </div>
            </div>


        </div>
    </div>
    <div *ngIf="isBrowser==true"  class="row"
        style="background: #EEF0F2;padding: 3rem;margin-top: 2rem;align-items: center;justify-content: center;">
        <h3 class="services" translate>freeServices</h3>
        <div class="custom-carousel" style="margin-top: 1rem">

            <p-carousel [value]="images2" styleClass="custom-carousel" [numVisible]="4" [numScroll]="2"
                [circular]="true" [autoplayInterval]="4000" [responsiveOptions]="responsiveOptions">
                <ng-template let-product pTemplate="item">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-12">
                                <a [href]="product.attributes.url" target="_blank">
                                    <img [src]="product.attributes.banner.data.attributes.url"
                                        [alt]="product.attributes.title" class="img-fluid" style=" float: left;
                                        width:  250px;
                                        height: 150px;
                                        object-fit: contain;">
                                </a>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </p-carousel>
        </div>
    </div>
    <div class="row" style="margin-top: 2rem;">
        <div class="col-md-10">
            <h3 class="m1" translate>eventsDays</h3>
        </div>
        <div class="m1 col-md-2"><a style="border-radius: 8px;
            border: 1px solid #026AA2;
            background: #026AA2;
            padding: 10px 10px; 
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);color: white;text-decoration: none;"
                routerLink="/category/3/1" translate>more</a></div>
        <div class="m1 col-md-12" style="margin-bottom: 2rem;">
            <p translate>learnMoreEvents</p>
        </div>
        <div class="col-md-4">
            <p-calendar [(ngModel)]="date" [inline]="true" [showOtherMonths]='false' class="max-w-full"
                (onMonthChange)="onMonthChange($event)" (onSelect)="onSelect(date)">
                <ng-template pTemplate="date" let-date>
                    <span [ngClass]="checkDateForHoliday(date) ==true ? 'eventDay' : 'normalDay'">{{date.day}}</span>
                </ng-template>
            </p-calendar>
        </div>
        <div class="col-md-8">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-4" *ngFor="let item of events">
                        <div class="col-md-12">
                            <img [src]="item?.attributes.cover" class="img-fluid"
                                style="object-fit: cover;height: 12rem;">
                        </div>
                        <div class="col-md-12">
                            <p>{{item?.attributes.published}}</p>
                            <h5 style="height: 10rem;min-height: 10rem;font-size: 1rem;">{{item?.attributes.title}}</h5>
                            <a class="btn_primary" style='font-size: 1rem;' routerLink="/page/{{item.id}}"
                                translate>more</a>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
    <!-- <div class="row yy" >
        <div class="col-md-4"
            style="display: flex;flex-direction: column;justify-content: center;align-items: center;padding: 1rem;">
            <img src="/assets/zap-fast.png" class="img-fluid"
                style="align-self: flex-start;background-color: white;padding: 0.5rem;border-radius: 50%;margin: 0.3rem;">
            <h4 translate>viewServices</h4>
            <p translate>informEnterpriseServices</p>
        </div>
        <div class="col-md-4" style="padding: 1rem;">
            <a routerLink="/category/9/1" style="color: white;text-decoration: none;">
                <h5 translate>contestsSupplies</h5>
                <p style="font-size: 14px;" translate>text10</p>
            </a>

            <a href="https://insuranceregistry.uhc.gr/" target="_blank" style="color: white;text-decoration: none;">
                <h5 translate>marketStudies</h5>
                <p style="font-size: 14px;" translate>text1</p>
            </a>

            <a href="https://elearning.uhc.gr/" target="_blank" style="color: white;text-decoration: none;">
                <h5 translate>exportLists</h5>
                <p style="font-size: 14px;" translate>text2</p>
            </a>

            <a href="https://businessplatform.uhc.gr/" target="_blank" style="color: white;text-decoration: none;">
                <h5 translate>cooperationsSeeking</h5>
                <p style="font-size: 14px;" translate>text3</p>
            </a>
            <a href="https://europa.eu/youreurope/business/index_el.htm" target="_blank" style="color: white;text-decoration: none;">
                <h5 translate>commercialProvisions</h5>
                <p style="font-size: 14px;" translate>text4</p>
            </a>
        </div>
        <div class="col-md-4" style="padding: 1rem;">
            <a href="https://womeninbusiness.uhc.gr/" target="_blank" style="color: white;text-decoration: none;">
                <h5 translate>femaleEntrepreneurship</h5>
                <p style="font-size: 14px;" translate>text6</p>
            </a>

            <a routerLink="/category/10/1" style="color: white;text-decoration: none;">
                <h5 translate>aboutTaxing</h5>
                <p style="font-size: 14px;" translate>text7</p>
            </a>

            <a href="https://www.directmarket.gr/dmarket/marketplc/index.jsp?redirect=true" target="_blank" style="color: white;text-decoration: none;">
                <h5 translate>branchStudies</h5>
                <p style="font-size: 14px;" translate>text5</p>
            </a>

            <a href="https://warranty.uhc.gr/" target="_blank" style="color: white;text-decoration: none;">
                <h5 translate>warranty</h5>
                <p style="font-size: 14px;" translate>text8</p>
            </a>
            <a href="https://www.filoxeno.com/search/intercept?type=accommodation&place=%CE%91%CF%81%CE%B3%CE%BF%CE%BB%CE%AF%CE%B4%CE%B1%2C+%CE%95%CE%BB%CE%BB%CE%AC%CE%B4%CE%B1&lat=37.6525404&lon=22.858217&polygonType=state&okeywords=&noDates=on&clear=clear" target="_blank" style="color: white;text-decoration: none;">
                <h5>filoxeno.com</h5>
                <p style="font-size: 14px;" translate>text9</p>
            </a>
        </div>

    </div> -->
   
    <ng-template #content let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title" translate>{{popupData.attributes.title}}</h4>
            <button type="button" class="btn close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div style="margin-top: 3vh;" [innerHTML]="body"></div>
        </div>
    </ng-template>