import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ApiService } from '../app.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Paginator } from 'primeng/paginator';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {

  constructor(private api: ApiService, private _Activatedroute: ActivatedRoute, private translate: TranslateService, private spinner: NgxSpinnerService,
    private router: Router,private title: Title) { }

  id: any
  category: any
  pages: any
  apiurl = environment.apiUrlPublic
  currentPages: any[] = [];
  page: any
  first;
  dataPerPage: number = 9; //for each page, the number of data (in our case data=pages from strapi) to be displayed is 10
  totalRecords: number; //the number of total strapi-pages that contain in title or body one or more words that user searched
  numberOfPages: number; //the number of paginator pages. For example, if we have 20 data (strapi-pages) and we want to display 10 data
  @ViewChild('paginator', { static: false }) public paginator: Paginator;

  async ngOnInit() {
    this._Activatedroute.params.subscribe(async params => {
      this.spinner.show();
      
      // Defaults to 0 if no query param provided.
      this.id = params['id'] || 0;
      this.page = params['page'] || 1;
      this.currentPages = []
      this.pages = await this.fetchElements(this.id, 9, 'el');
      this.totalRecords = this.pages.meta.pagination.total;
      this.numberOfPages = Math.ceil(this.totalRecords / this.dataPerPage);
      let cat: any = await this.api.getCategory(this.id).toPromise()
      this.category = cat.data
      this.title.setTitle(this.category.attributes.name ); 
      this.spinner.hide();
    });


    //react to language changign event
    this.translate.onLangChange.subscribe(async (event: LangChangeEvent) => {
      this._Activatedroute.params.subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.id = params['id'] || 0;
      });
    })

  }

  async fetchElements(id: String, limit, locale): Promise<any> {

    let pages: any = await this.api.getCategory2(id, limit, locale, this.page).toPromise()
    this.first = this.page - 1;
    pages.data.forEach((element: any) => {

      var date2 = new Date(element.attributes.published);
      var n = date2.toLocaleDateString('el-GR', { timeZone: 'Europe/Athens' })
      element.attributes.published = n

      if (element.attributes.cover.data) {
        element.attributes.cover.data.attributes.url = `${this.apiurl}${element.attributes.cover.data.attributes.url}`
      } else {
        let x = this.randomIntFromInterval(1, 2)
        element.cover = `/assets/${x}.jpg`
      }
    });
    return pages;
  }

  randomIntFromInterval(min: number, max: number) { // min and max included 
    let x = Math.floor(Math.random() * (max - min + 1) + min)
    return `fokida${x}`
  }
  async paginate(event: any) {
    this.router.navigate([`/category/${this.id}/${event.page + 1}`]);
    this.page = event.page + 1
    this.pages = await this.fetchElements(this.id, 9, 'el');
  }

}
