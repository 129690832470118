<div class="container">
    <div class="footer-cta">
        <div class="row">
            <div class="col-xl-4 col-md-4 mb-30">
                <div class="single-cta">
                    <i class="bi bi-geo-alt"></i>
                    <div class="cta-text">
                        <h4 translate>offices</h4>
                        <span translate>officeAddress</span>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-md-4 mb-30">
                <div class="single-cta">
                    <i class="bi bi-telephone"></i>
                    <div class="cta-text">
                        <h4 translate>phones</h4>
                        <span>2265028697, 2265022185</span>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-md-4 mb-30">
                <div class="single-cta">
                    <i class="bi bi-envelope"></i>
                    <div class="cta-text">
                        <h4>Εmail</h4>
                        <span>info@epimelitiriofokidas.gr</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-content">
        <div class="row">
            <div class="col-xl-4 col-lg-4 mb-50">
                <div class="footer-widget">
                    <div class="footer-logo">
                        <a routerLink="/"><img [src]=[imageUrl] class="img-fluid" alt="logo" style="width: 8rem;"></a>
                    </div>
                    <div class="footer-text">
                        <p translate>siteTitle</p>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 mb-30">
                <div class="footer-widget">
                    <div class="footer-widget-heading">
                        <h3 translate>usefulLinks</h3>
                    </div>
                    <ul>
                        <li><a routerLink="/" translate>homepage</a></li>
                        <!-- <li><a href="#" translate>termsOfUse</a></li> -->
                        <!-- <li><a [routerLink]="links &&  links.data && links.data.attributes.politiki_prostasias"
                                translate>privacyPolicy</a></li>
                        <li><a [routerLink]="links && links.data && links.data.attributes.dilosi_prosvasimotitas"
                                translate>accessibilityStatement</a></li> -->
                        <li><a routerLink="/contact" translate>contact</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 mb-50">
                <div class="footer-widget" routerLink="/newsletter" style="cursor: pointer;">
                    <div class="footer-widget-heading" >
                        <h3 translate>newsletterRegistration</h3>
                    </div>
                    <div class="footer-text mb-25">
                        <p translate>newsletterRegistrationMessage</p>
                    </div>
                    <!-- <div class="subscribe-form">
                        <form action="#">
                            <input type="text" placeholder="Εγγραφή" disabled>
                            <button ><i class="material-icons">send</i></button>
                        </form>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="row" style="display: flex;padding-top: 32px;align-items: center;align-self: stretch;border-top: 1px solid var(--gray-600, #475467);margin-top: 1rem;">
            <div class="col-md-5">
                <p style="color: var(--gray-300, #D0D5DD);

                /* Text md/Regular */
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;">© {{date}}, All Rights Reserved <a href="/" style="font-size: 15px;">ΕΠΙΜΕΛΗΤΗΡΙΟ ΦΩΚΙΔΟΣ</a></p>
            </div>
            <div class="col-md-7" style="display: flex;justify-content: flex-end;">
                <ul class="list">
                    <li class="footerList"> <a  [routerLink]="links &&  links.data && links.data.attributes.politiki_prostasias"
                        translate>termsOfUse</a>
                
                    </li>
                    <li class="footerList"><a [routerLink]="links &&  links.data && links.data.attributes.politiki_prostasias"
                        translate>privacyPolicy</a>
                    </li>
                    <li  class="footerList"><a [routerLink]="links && links.data && links.data.attributes.dilosi_prosvasimotitas"
                        translate>accessibilityStatement</a></li>
                </ul>
            </div>
            <div class="col-md-5">
                <a style="color: var(--gray-300, #D0D5DD);
margin-top: 1rem;
                /* Text md/Regular */
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;" href="https://www.sga.gr/" target="_blank">Web Design & Development SGA</a>
            </div>
        </div>
    </div>
</div>

<!-- <div class="container" style="background: #1c2938;padding: 3rem;padding-left: 6rem;">
    <div class="row">
        <div class="col-xl-5 col-lg-5 text-center text-lg-left">
            <div class="copyright-text">
                <p>Copyright &copy; {{date}}, All Rights Reserved <a href="/">ARCCI</a></p>
            </div>
        </div>
        <div class="col-xl-7 col-lg-7 d-none d-lg-block text-right">
            <div class="footer-menu">
                <a href="https://www.sga.gr/"><img [src]=[imageUrl2] class="img-fluid" alt="logo-sga"></a>
             
            </div>
        </div>
    </div>
</div> -->